/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { defineCustomElements } from "design/dist/esm/loader"
import "design/dist/design/design.css"
defineCustomElements(window)
